import React from 'react';
import './CurrentBook.css';

const CurrentBook = ({ title, author, coverImage }) => {
  return (
    <div className="current-book">
      <h2 className="current-book__title">Читаю сейчас</h2>
      <div className="current-book__container">
        <div className="current-book__card">
          <img 
            src={coverImage || '/images/placeholder_book.png'} 
            alt={title} 
            className="current-book__image"
          />
          <div className="current-book__info">
            <h3 className="current-book__book-title">{title}</h3>
            <p className="current-book__author">{author}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentBook; 
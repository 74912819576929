const MAX_FILE_SIZE = 1024 * 1024; // 1MB в байтах

export const validateAndCompressImage = (file) => {
  return new Promise((resolve, reject) => {
    if (file.size > MAX_FILE_SIZE) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          
          // Вычисляем новые размеры, сохраняя пропорции
          let width = img.width;
          let height = img.height;
          const maxDimension = 1200; // Максимальный размер стороны
          
          if (width > height && width > maxDimension) {
            height = (height * maxDimension) / width;
            width = maxDimension;
          } else if (height > maxDimension) {
            width = (width * maxDimension) / height;
            height = maxDimension;
          }
          
          canvas.width = width;
          canvas.height = height;
          
          ctx.drawImage(img, 0, 0, width, height);
          
          // Конвертируем в JPEG с качеством 0.7
          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            }));
          }, 'image/jpeg', 0.7);
        };
      };
      
      reader.onerror = (error) => reject(error);
    } else {
      resolve(file);
    }
  });
}; 
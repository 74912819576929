// src/components/profile/HeroProfileSection.jsx
import React from 'react';
import './HeroProfileSection.css';

const HeroProfileSection = ({ fullName, city, avatar, aboutMe }) => (
  <section className="hero-profile">
    <div className="hero-profile__content">
      <div className="hero-profile__text">
        <h1 className="hero-profile__name">{fullName}</h1>
        <p className="hero-profile__city">{city}</p>
        <p className="hero-profile__aboutme">{aboutMe}</p>
      </div>
      <div className="hero-profile__avatar">
        <img src={avatar} alt={`${fullName}'s avatar`} className="hero-profile__img" />
      </div>
    </div>
  </section>
);

export default HeroProfileSection;

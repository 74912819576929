import React, { useState, useContext } from 'react';
import './ReadingStats.css';
import ReadingCalendar from './ReadingCalendar';
import { AuthContext } from '../../context/AuthContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ReadingStats = ({ totalReadingTime, longestStreak, readingDates, onStatsUpdate, canAddReadingTime, tagId }) => {
  const [showDateModal, setShowDateModal] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const { authorizedFetch } = useContext(AuthContext);
  const apiUrl = process.env.REACT_APP_API_URL;
  
  // Функция для открытия модального окна выбора даты
  const handleAddReadingTime = () => {
    setShowDateModal(true);
  };

  // Модифицированная функция для обработки выбора даты
  const handleDateSelect = (date) => {
    setSelectedDate(date);
    setShowDateModal(false);
    setShowTimeModal(true);
  };

  // Функция для закрытия модальных окон
  const handleCloseModals = () => {
    setShowDateModal(false);
    setShowTimeModal(false);
    setSelectedDate(null);
    setHours(0);
    setMinutes(0);
  };

  // Обработчики для полей ввода времени
  const handleHoursFocus = (e) => {
    if (e.target.value === '0') {
      e.target.value = '';
    }
  };

  const handleHoursBlur = (e) => {
    if (e.target.value === '') {
      setHours(0);
    }
  };

  const handleMinutesFocus = (e) => {
    if (e.target.value === '0') {
      e.target.value = '';
    }
  };

  const handleMinutesBlur = (e) => {
    if (e.target.value === '') {
      setMinutes(0);
    }
  };

  // Функция для отправки данных на сервер
  const handleSubmitReadingTime = async () => {
    // Убедимся, что у нас есть числовые значения
    const hoursValue = parseInt(hours) || 0;
    const minutesValue = parseInt(minutes) || 0;

    if (!selectedDate || (hoursValue === 0 && minutesValue === 0)) {
      alert('Пожалуйста, выберите дату и укажите время чтения');
      return;
    }

    try {
      const formattedDate = `${selectedDate.toISOString().split('T')[0]} 00:00:00.000000`;
      
      const response = await authorizedFetch(`${apiUrl}/v1/reading/manual`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          tag_id: tagId, // Используем tag_id из пропсов
          date: formattedDate,
          hours: hoursValue,
          minutes: minutesValue
        }),
      });

      if (!response.ok) {
        throw new Error('Ошибка при отправке данных');
      }

      // Обновляем статистику после успешного добавления
      if (onStatsUpdate) {
        onStatsUpdate();
      }

      // Закрываем модальные окна
      handleCloseModals();
    } catch (error) {
      console.error('Ошибка при добавлении времени чтения:', error);
      alert('Произошла ошибка при добавлении времени чтения');
    }
  };

  // Добавим логирование для отладки
  console.log('ReadingStats props:', { totalReadingTime, longestStreak, readingDates, canAddReadingTime, tagId });
  
  return (
    <div className="reading-stats">
      <h2 className="reading-stats__title">Статистика чтения</h2>
      <div className="reading-stats__container">
        <div className="reading-stats__card">
          <div className="reading-stats__grid">
            <div className="reading-stats__stat-item">
              <div className="reading-stats__stat-value">{totalReadingTime}</div>
              <div className="reading-stats__stat-label">Общее время чтения</div>
            </div>
            <div className="reading-stats__stat-item">
              <div className="reading-stats__stat-value">{longestStreak}</div>
              <div className="reading-stats__stat-label">Самая длинная цепочка дней</div>
            </div>
          </div>

          {/* Календарь активности чтения */}
          <ReadingCalendar readingDates={readingDates || []} />

          {/* Кнопка добавления времени чтения только для собственного профиля с трекером */}
          {canAddReadingTime && (
            <div className="reading-stats__actions">
              <button 
                className="reading-stats__add-button" 
                onClick={handleAddReadingTime}
              >
                Добавить время чтения
              </button>
            </div>
          )}

          {/* Модальное окно выбора даты с react-datepicker */}
          {showDateModal && (
            <div className="reading-stats__modal-overlay">
              <div className="reading-stats__modal">
                <h3>Выберите дату</h3>
                <div className="reading-stats__date-picker">
                  <DatePicker
                    selected={selectedDate || new Date()}
                    onChange={handleDateSelect}
                    maxDate={new Date()}
                    inline
                    locale="ru"
                    dateFormat="dd.MM.yyyy"
                  />
                </div>
                <div className="reading-stats__modal-actions">
                  <button onClick={handleCloseModals}>Отмена</button>
                </div>
              </div>
            </div>
          )}

          {/* Модальное окно ввода времени */}
          {showTimeModal && (
            <div className="reading-stats__modal-overlay">
              <div className="reading-stats__modal">
                <h3>Укажите время чтения</h3>
                <p>Дата: {selectedDate?.toLocaleDateString('ru-RU')}</p>
                <div className="reading-stats__time-inputs">
                  <div className="reading-stats__time-input">
                    <label>Часы:</label>
                    <input 
                      type="number" 
                      min="0" 
                      max="24" 
                      value={hours} 
                      onChange={(e) => setHours(e.target.value)}
                      onFocus={handleHoursFocus}
                      onBlur={handleHoursBlur}
                    />
                  </div>
                  <div className="reading-stats__time-input">
                    <label>Минуты:</label>
                    <input 
                      type="number" 
                      min="0" 
                      max="59" 
                      value={minutes} 
                      onChange={(e) => setMinutes(e.target.value)}
                      onFocus={handleMinutesFocus}
                      onBlur={handleMinutesBlur}
                    />
                    <small className="reading-stats__input-hint">Введите значение от 0 до 59</small>
                  </div>
                </div>
                <div className="reading-stats__modal-actions">
                  <button onClick={handleCloseModals}>Отмена</button>
                  <button onClick={handleSubmitReadingTime}>Записать</button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReadingStats; 
import React, { createContext, useState, useEffect, useCallback } from 'react';

// Create the context
export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // State management
  const [authState, setAuthState] = useState({
    accessToken: null,
    refreshToken: null,
    currentUserId: null,
    username: null,
    userTagId: null,
    isInitialized: false
  });

  const { accessToken, refreshToken, currentUserId, username, userTagId, isInitialized } = authState;

  // Initialize auth state from localStorage
  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    const storedRefreshToken = localStorage.getItem('refreshToken');
    const storedUserId = localStorage.getItem('currentUserId');
    const storedUsername = localStorage.getItem('username');
    const storedTagId = localStorage.getItem('userTagId');

    if (storedToken && storedRefreshToken && storedUserId && storedUsername) {
      setAuthState(prev => ({
        ...prev,
        accessToken: storedToken,
        refreshToken: storedRefreshToken,
        currentUserId: Number(storedUserId),
        username: storedUsername,
        userTagId: storedTagId,
      }));
    }
    
    setAuthState(prev => ({ ...prev, isInitialized: true }));
  }, []);

  // Login handler
  const login = useCallback((token, userId, usernameValue, refreshTokenValue) => {
    setAuthState(prev => ({
      ...prev,
      accessToken: token,
      refreshToken: refreshTokenValue,
      currentUserId: userId,
      username: usernameValue
    }));

    // Store auth data in localStorage
    localStorage.setItem('accessToken', token);
    localStorage.setItem('refreshToken', refreshTokenValue);
    localStorage.setItem('currentUserId', userId);
    localStorage.setItem('username', usernameValue);

    console.log('[AuthContext] Logged in');
  }, []);

  // Logout handler
  const logout = useCallback(() => {
    setAuthState(prev => ({
      ...prev,
      accessToken: null,
      refreshToken: null,
      currentUserId: null,
      username: null,
      userTagId: null
    }));

    // Clear auth data from localStorage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('currentUserId');
    localStorage.removeItem('username');
    localStorage.removeItem('userTagId');
    
    console.log('[AuthContext] Logged out');
  }, []);

  /**
   * Refresh access token
   * Returns a new token or null if refresh failed
   */
  const refreshAccessToken = useCallback(async () => {
    if (!refreshToken) return null;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/refresh`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ refresh_token: refreshToken }),
        }
      );

      if (!response.ok) {
        console.log('[AuthContext] Failed to refresh token, logging out');
        logout();
        return null;
      }

      const data = await response.json();
      const newAccess = data.access_token;

      setAuthState(prev => ({
        ...prev,
        accessToken: newAccess,
        refreshToken: data.refresh_token || prev.refreshToken
      }));

      localStorage.setItem('accessToken', newAccess);
      
      if (data.refresh_token) {
        localStorage.setItem('refreshToken', data.refresh_token);
      }

      console.log('[AuthContext] Token refreshed successfully');
      return newAccess;
    } catch (err) {
      console.error('[AuthContext] Token refresh error:', err);
      logout();
      return null;
    }
  }, [refreshToken, logout]);

  // Wrapper around fetch that handles 401 errors
  const authorizedFetch = useCallback(async (url, options = {}) => {
    if (!accessToken) {
      const gotToken = await refreshAccessToken();
      if (!gotToken) {
        logout();
        return new Response(JSON.stringify({ detail: "Unauthorized" }), { 
          status: 401,
          headers: { 'Content-Type': 'application/json' }
        });
      }
    }

    let headers = {
      ...options.headers,
      Authorization: `Bearer ${accessToken}`,
    };

    let response = await fetch(url, { ...options, headers });

    if (response.status === 401) {
      console.log('[AuthContext] Received 401, attempting token refresh');
      const newToken = await refreshAccessToken();

      if (!newToken) {
        return response;
      }

      headers = {
        ...options.headers,
        Authorization: `Bearer ${newToken}`,
      };
      
      response = await fetch(url, { ...options, headers });

      if (response.status === 401) {
        console.log('[AuthContext] Second 401 received - logging out');
        logout();
      }
    }

    return response;
  }, [accessToken, refreshAccessToken, logout]);

  // Update userTagId when it changes
  const updateUserTagId = useCallback((tagId) => {
    setAuthState(prev => ({ ...prev, userTagId: tagId }));
    if (tagId) {
      localStorage.setItem('userTagId', tagId);
    } else {
      localStorage.removeItem('userTagId');
    }
  }, []);

  // Context value
  const contextValue = {
    accessToken,
    refreshToken,
    currentUserId,
    username,
    userTagId,
    isInitialized,
    login,
    logout,
    refreshAccessToken,
    authorizedFetch,
    updateUserTagId
  };

  if (!isInitialized) {
    return null;
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

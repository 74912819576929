import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import HeroProfileSection from '../components/profile/HeroProfileSection';
import Navbar from '../components/common/Navbar';
import Footer from '../components/common/Footer';
import AboutClub from '../components/common/AboutMe';
import CurrentBook from '../components/book/CurrentBook';
import './ProfilePage.css';
import { AuthContext } from '../context/AuthContext';
import ReadingStats from '../components/stats/ReadingStats';

const UserProfilePage = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const { userId } = useParams();
  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const { accessToken, currentUserId, authorizedFetch, isInitialized } = useContext(AuthContext);
  const [readingStats, setReadingStats] = useState(null);
  const [hasTrackerTag, setHasTrackerTag] = useState(false);

  // Перемещаем логику получения файла внутрь компонента
  const getFileUrl = async (key) => {
    if (!key) return null;
    
    try {
      const response = await authorizedFetch(`${apiUrl}/v1/files/download/${key}`, {
        headers: {
          'Accept': 'image/*'
        },
        credentials: 'include'
      });
      
      if (!response.ok) {
        console.error('File download failed:', response.status, response.statusText);
        return null;
      }
      
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error('Error getting file:', error);
      return null;
    }
  };

  // Add this function to format time
  const formatReadingTime = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}ч ${minutes}мин`;
  };

  useEffect(() => {
    if (isInitialized && !accessToken) {
      navigate('/auth/login', {
        replace: true,
        state: { from: `/users/${userId}`, message: "Пожалуйста, войдите в систему" }
      });
      return;
    }

    if (accessToken) {
      const fetchData = async () => {
        try {
          // Fetch profile data
          const profileResponse = await authorizedFetch(`${apiUrl}/v1/users/${userId}/profile`);
          
          if (!profileResponse.ok) {
            throw new Error('Ошибка при загрузке данных профиля');
          }
          
          const profileData = await profileResponse.json();
          
          // Check if user has a tracker tag
          const userHasTag = profileData.tag_id || false;
          setHasTrackerTag(userHasTag);
          
          // Process profile data
          const avatarUrl = await getFileUrl(profileData.photo);
          const bookImageUrl = await getFileUrl(profileData.current_reading_photo);
          
          setUserProfile({
            fullName: `${profileData.first_name || 'Имя'} ${profileData.last_name || 'Фамилия'}`,
            city: profileData.city || 'Город не указан',
            avatar: avatarUrl || '/images/placeholder_avatar.png',
            aboutMe: profileData.about_me || 'Пользователь пока не добавил информацию о себе.',
            currentBook: profileData.current_reading_title || 'Название книги не указано',
            bookImage: bookImageUrl || '/images/placeholder_book.png',
            bookAuthor: profileData.current_reading_author || 'Автор не указан',
            bookDescription: profileData.current_reading_description || 'Описание книги отсутствует.',
            tag_id: profileData.tag_id,
          });
          
          // Only fetch reading stats if user has a tracker tag
          if (userHasTag) {
            const statsResponse = await authorizedFetch(`${apiUrl}/v1/users/${userId}/reading_stats`);
            
            if (statsResponse.ok) {
              const statsData = await statsResponse.json();
              
              setReadingStats({
                totalReadingMinutes: statsData.total_reading_minutes || 0,
                longestStreak: statsData.longest_streak || 0,
                readingDates: statsData.reading_days || []
              });
              
              console.log('Статистика чтения:', statsData);
            } else {
              console.error('Ошибка при загрузке статистики:', statsResponse.status);
            }
          }
        } catch (error) {
          console.error('Ошибка при загрузке данных:', error);
          setUserProfile(null);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [userId, apiUrl, accessToken, authorizedFetch, navigate, isInitialized]);

  // Если профиль ещё не загружен
  if (isLoading) {
    return <div className="loading">Загрузка...</div>;
  }

  // Определяем, может ли пользователь редактировать профиль
  const canEdit = Number(userId) === currentUserId;
  
  // Определяем, может ли пользователь добавлять время чтения
  const canAddReadingTime = canEdit && hasTrackerTag;

  return (
    <div className="profile-page">
      <Navbar />
      <HeroProfileSection
        fullName={userProfile.fullName}
        city={userProfile.city}
        avatar={userProfile.avatar}
        aboutMe={userProfile.aboutMe}
        hasTrackerTag={hasTrackerTag}
      />
      
      <div className="profile-content">
        <CurrentBook
          title={userProfile.currentBook}
          author={userProfile.bookAuthor}
          coverImage={userProfile.bookImage}
        />
        
        <div className="stats-section">

          
          {hasTrackerTag ? (
            <div className="stats-container">
              <ReadingStats
                totalReadingTime={formatReadingTime(readingStats?.totalReadingMinutes || 0)}
                longestStreak={readingStats?.longestStreak || 0}
                readingDates={readingStats?.readingDates || []}
                canAddReadingTime={canAddReadingTime}
                tagId={userProfile.tag_id}
                onStatsUpdate={() => {
                  // Функция для обновления статистики после добавления времени чтения
                  const fetchStats = async () => {
                    try {
                      const statsResponse = await authorizedFetch(`${apiUrl}/v1/users/${userId}/reading_stats`);
                      
                      if (!statsResponse.ok) {
                        throw new Error('Ошибка при загрузке данных статистики');
                      }
                      
                      const statsData = await statsResponse.json();
                      
                      setReadingStats({
                        totalReadingMinutes: statsData.total_reading_minutes || 0,
                        longestStreak: statsData.longest_streak || 0,
                        readingDates: statsData.reading_days || []
                      });
                    } catch (error) {
                      console.error('Ошибка при обновлении статистики:', error);
                    }
                  };
                  
                  fetchStats();
                }}
              />
            </div>
          ) : (
            <div className="no-tracker-message">
              <div className="tracker-icon">
                <img src="/images/tracker-badge.svg" alt="Трекер чтения" />
              </div>
              <h3>Статистика чтения недоступна</h3>
              {canEdit ? (
                <div className="tracker-info">
                  <p>Статистика чтения доступна только пользователям с книжной закладкой-трекером.</p>
                  <p>С помощью закладки-трекера вы сможете:</p>
                  <ul>
                    <li>Отслеживать время, проведенное за чтением</li>
                    <li>Видеть свой прогресс и достижения</li>
                    <li>Устанавливать и достигать цели по чтению</li>
                  </ul>
                </div>
              ) : (
                <p className="other-user-message">У данного пользователя нет книжной закладки-трекера, поэтому статистика чтения не ведется.</p>
              )}
            </div>
          )}
        </div>
      </div>

      {canEdit && (
        <div className="edit-profile-button-container">
          <button
            onClick={() => navigate(`/users/${userId}/edit`)}
            className="edit-profile-button"
          >
            Редактировать профиль
          </button>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default UserProfilePage;

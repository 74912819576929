import React, { useEffect, useRef, useState } from 'react';
import ePub from 'epubjs';
import styles from './reading.module.css';

const ReadingPage = () => {
  const viewerRef = useRef(null);
  const [book, setBook] = useState(null);
  const [bookmarks, setBookmarks] = useState([]);
  const [rendition, setRendition] = useState(null);

  useEffect(() => {
    // Load bookmarks from localStorage
    const savedBookmarks = JSON.parse(localStorage.getItem('bookmarks') || '[]');
    setBookmarks(savedBookmarks);

    // Initialize book
    const bookUrl = '/ivan_alekseevich_bunin-okayannie_dni-5e6d74fe05045.epub';
    const newBook = ePub(bookUrl);
    setBook(newBook);

    // Render book
    const newRendition = newBook.renderTo(viewerRef.current, {
      width: '100%',
      height: '100%',
      flow: 'scrolled-doc',
      manager: 'continuous',
      spread: 'none'
    });

    setRendition(newRendition);

    // Load all sections of the book
    newBook.ready.then(() => {
      Promise.all(newBook.spine.spineItems.map(item => item.load(newBook.load.bind(newBook))))
        .then(() => {
          newRendition.display();
        })
        .catch(error => {
          console.error('Error loading book sections:', error);
        });
    });

    // Load last reading position
    const lastCfi = localStorage.getItem('lastReadingPosition');
    if (lastCfi) {
      newRendition.display(lastCfi);
    }

    // Save position periodically
    const savePosition = () => {
      const currentCfi = newRendition.location.start.cfi;
      localStorage.setItem('lastReadingPosition', currentCfi);
    };

    newRendition.on('relocated', savePosition);

    return () => {
      newBook.destroy();
    };
  }, []);

  const addBookmark = async () => {
    if (!rendition) return;

    const cfi = rendition.location.start.cfi;
    const text = await book.getRange(cfi).then(range => range.toString().slice(0, 100) + '...');
    
    const newBookmark = {
      cfi,
      text,
      date: new Date().toISOString(),
    };

    const updatedBookmarks = [...bookmarks, newBookmark];
    setBookmarks(updatedBookmarks);
    localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
  };

  const removeBookmark = (index) => {
    const updatedBookmarks = bookmarks.filter((_, i) => i !== index);
    setBookmarks(updatedBookmarks);
    localStorage.setItem('bookmarks', JSON.stringify(updatedBookmarks));
  };

  const goToBookmark = (cfi) => {
    if (rendition) {
      rendition.display(cfi);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <button className={styles.addBookmark} onClick={addBookmark}>
          Add Bookmark
        </button>
        <div className={styles.bookmarksList}>
          {bookmarks.map((bookmark, index) => (
            <div key={index} className={styles.bookmarkItem}>
              <div 
                className={styles.bookmarkContent}
                onClick={() => goToBookmark(bookmark.cfi)}
              >
                <p className={styles.bookmarkText}>{bookmark.text}</p>
                <p className={styles.bookmarkDate}>
                  {new Date(bookmark.date).toLocaleDateString()}
                </p>
              </div>
              <button 
                className={styles.removeBookmark}
                onClick={() => removeBookmark(index)}
              >
                ×
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.reader} ref={viewerRef}></div>
    </div>
  );
};

export default ReadingPage; 
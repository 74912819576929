import React, { useState, useEffect } from 'react';
import './ReadingCalendar.css';

const ReadingCalendar = ({ readingDates }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendarData, setCalendarData] = useState(null);
  
  // Функция для перехода к предыдущему месяцу
  const goToPreviousMonth = () => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() - 1);
      return newDate;
    });
  };
  
  // Функция для перехода к следующему месяцу
  const goToNextMonth = () => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() + 1);
      return newDate;
    });
  };
  
  // Функция для форматирования названия месяца
  const formatMonthName = (date) => {
    return date.toLocaleString('ru-RU', { month: 'long', year: 'numeric' });
  };
  
  useEffect(() => {
    // Получаем текущий месяц и год из состояния
    const monthIndex = currentDate.getMonth();
    const year = currentDate.getFullYear();
    
    // Получаем название месяца
    const monthName = formatMonthName(currentDate);
    
    // Получаем количество дней в месяце
    const daysInMonth = new Date(year, monthIndex + 1, 0).getDate();
    
    // Получаем день недели для первого дня месяца (0 - воскресенье, 6 - суббота)
    const firstDayOfMonth = new Date(year, monthIndex, 1).getDay();
    
    // Создаем массив дней
    const days = [];
    
    // Добавляем пустые ячейки для выравнивания календаря
    // Преобразуем воскресенье (0) в 7 для удобства расчета
    const firstDayIndex = firstDayOfMonth === 0 ? 7 : firstDayOfMonth;
    for (let j = 1; j < firstDayIndex; j++) {
      days.push({ day: null, isReading: false });
    }
    
    // Добавляем дни месяца
    for (let day = 1; day <= daysInMonth; day++) {
      // Создаем дату в формате YYYY-MM-DD
      const dateObj = new Date(year, monthIndex, day);
      const formattedDate = dateObj.toISOString().split('T')[0];
      
      // Проверяем, есть ли эта дата в списке дат чтения
      const isReadingDay = readingDates.some(readingDate => {
        const readingDateObj = new Date(readingDate);
        return (
          readingDateObj.getDate() === day &&
          readingDateObj.getMonth() === monthIndex &&
          readingDateObj.getFullYear() === year
        );
      });
      
      days.push({
        day,
        isReading: isReadingDay,
        date: formattedDate
      });
    }
    
    setCalendarData({
      name: monthName,
      year,
      days
    });
  }, [currentDate, readingDates]);
  
  if (!calendarData) {
    return <div>Загрузка календаря...</div>;
  }
  
  return (
    <div className="reading-calendar">
      <h3 className="reading-calendar__title">Активность чтения</h3>
      
      <div className="reading-calendar__navigation">
        <button 
          className="reading-calendar__nav-button" 
          onClick={goToPreviousMonth}
          aria-label="Предыдущий месяц"
        >
          &larr;
        </button>
        <h4 className="reading-calendar__month-title">
          {calendarData.name}
        </h4>
        <button 
          className="reading-calendar__nav-button" 
          onClick={goToNextMonth}
          aria-label="Следующий месяц"
        >
          &rarr;
        </button>
      </div>
      
      <div className="reading-calendar__container">
        <div className="reading-calendar__weekdays">
          <div className="reading-calendar__weekday">Пн</div>
          <div className="reading-calendar__weekday">Вт</div>
          <div className="reading-calendar__weekday">Ср</div>
          <div className="reading-calendar__weekday">Чт</div>
          <div className="reading-calendar__weekday">Пт</div>
          <div className="reading-calendar__weekday">Сб</div>
          <div className="reading-calendar__weekday">Вс</div>
        </div>
        <div className="reading-calendar__days">
          {calendarData.days.map((day, dayIndex) => (
            <div 
              key={dayIndex} 
              className={`reading-calendar__day ${!day.day ? 'reading-calendar__day--empty' : ''} ${day.isReading ? 'reading-calendar__day--reading' : ''}`}
              title={day.date ? `${day.date}${day.isReading ? ': Чтение' : ''}` : ''}
            >
              {day.day}
            </div>
          ))}
        </div>
      </div>
      
      <div className="reading-calendar__legend">
        <div className="reading-calendar__legend-item">
          <div className="reading-calendar__legend-color"></div>
          <span>Нет чтения</span>
        </div>
        <div className="reading-calendar__legend-item">
          <div className="reading-calendar__legend-color reading-calendar__legend-color--reading"></div>
          <span>Чтение</span>
        </div>
      </div>
    </div>
  );
};

export default ReadingCalendar; 